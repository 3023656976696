import * as React from "react";
import "./index.scss";
import productList from "../../../constants/productList";
import { IProduct } from "../../../models/product.type";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductItem from "./product-item";

export interface IProductCarouselProps {
  selectedProduct: IProduct;
  setSelectedProduct: React.Dispatch<React.SetStateAction<IProduct>>;
  setCurrentImage: React.Dispatch<React.SetStateAction<number>>;
}

export default function ProductCarousel({
  selectedProduct,
  setSelectedProduct,
  setCurrentImage,
}: IProductCarouselProps) {
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const minScreenWidth = 1281;

  const [width, setWidth] = React.useState<number>(0);
  const carouselRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setWidth((carouselRef.current?.scrollWidth ? carouselRef.current?.scrollWidth : 0) - (carouselRef.current?.offsetWidth ?  carouselRef.current?.offsetWidth : 0));
  }, []);



  const productListDetail: IProduct[] = [
    ...productList
      .map((productCategory) =>
        productCategory.products.map((product) => product)
      )
      .flat(),
  ];
  const [index, setIndex] = React.useState(0);
  const [productGroups, setProductGroups] = React.useState(
    productListDetail.slice(0, 5)
  );

  const handlePrev = () => {
    const newIndex =
      (index - 1 + productListDetail.length) % productListDetail.length;
    const productGroupCount = Math.min(5, productListDetail.length - newIndex);
    const newProductGroups = [
      ...productListDetail.slice(newIndex, newIndex + productGroupCount),
      ...(productGroupCount < 5
        ? productListDetail.slice(0, 5 - productGroupCount)
        : []),
    ];

    setIndex(newIndex);
    setProductGroups(newProductGroups);
  };

  const handleNext = () => {
    const newIndex = (index + 1) % productListDetail.length;
    const productGroupCount = Math.min(5, productListDetail.length - newIndex);
    const newProductGroups = [
      ...productListDetail.slice(newIndex, newIndex + productGroupCount),
      ...(productGroupCount < 5
        ? productListDetail.slice(0, 5 - productGroupCount)
        : []),
    ];

    setIndex(newIndex);
    setProductGroups(newProductGroups);
  };

  if (windowWidth >= minScreenWidth)
    return (
      <div className="product-desktop-carousel">
        <button className="button" onClick={handlePrev}>
          <FontAwesomeIcon icon={faChevronLeft} className="text-2xl" />
        </button>
        <div className="product-list">
          {productGroups.map((product, index) => (
            <ProductItem
              key={index}
              product={product}
              setSelectedProduct={setSelectedProduct}
              setCurrentImage={setCurrentImage}
            />
          ))}
        </div>
        <button className="button" onClick={handleNext}>
          <FontAwesomeIcon icon={faChevronRight} className="text-2xl" />
        </button>
      </div>
    );
  return (
    <div className="product-mobile-carousel">
      <motion.div ref={carouselRef} className="carousel">
        <motion.div
          drag="x"
          dragConstraints={{ right: 0, left: -width}}
          className="inner-carousel"
          dragElastic={0.8}
        >
          {productListDetail.map((product, index) => (
            <ProductItem
              key={index}
              product={product}
              setSelectedProduct={setSelectedProduct}
              setCurrentImage={setCurrentImage}
            />
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
}
