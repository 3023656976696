import Layout from "./components/layout";
import HomePage from "./pages/home";
import ErrorPage from "./pages/error";

import { Suspense, useState } from "react";
import { Routes, Route } from "react-router-dom";
import ProductPage from "./pages/product";
import { ProductContext } from "./store/product-context";
import { IProduct } from "./models/product.type";
import productList from "./constants/productList";
import Leaf from "./components/shared/leafs";

function App() {
  const [selectedProduct, setSelectedProduct] = useState<IProduct>(
    productList[0].products[0]
  );

  return (
    <ProductContext.Provider value={{ selectedProduct, setSelectedProduct }}>
      <Leaf/>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route
            path="/product"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <ProductPage />
              </Suspense>
            }
          />
          <Route path="*" element={<ErrorPage />} />
        </Route>
      </Routes>
    </ProductContext.Provider>
  );
}

export default App;
