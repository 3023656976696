import * as React from "react";
import { IProduct } from "../models/product.type";

interface IProductContext {
    selectedProduct: IProduct;
    setSelectedProduct: React.Dispatch<React.SetStateAction<IProduct>>;
}

export const ProductContext = React.createContext<IProductContext>({
    selectedProduct: {
        name: "",
        price: [],
        ingredients: "",
        description: [],
        image: [],
    },
    setSelectedProduct: () => {}
});