import * as React from "react";
import { useEffect, useRef } from "react";
import "./index.scss";
import storyImage from "../../../assets/images/story.jpg";

export interface IStoryProps {}

export default function Story(props: IStoryProps) {
  const componentRef1 = useRef(null);
  const componentRef2 = useRef(null);

  useEffect(() => {
    const observer1 = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    });

    const observer2 = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    });

    const ref1 = componentRef1.current;
    const ref2 = componentRef2.current;
  
    if (ref1) {
      observer1.observe(ref1);
    }
  
    if (ref2) {
      observer2.observe(ref2);
    }
  
    return () => {
      if (ref1) {
        observer1.unobserve(ref1);
      }
  
      if (ref2) {
        observer2.unobserve(ref2);
      }
    };
  }, [componentRef1, componentRef2]);

  const handleIntersection = (entries: any) => {
    entries.forEach((entry: any) => {
      if (entry.isIntersecting) {
        entry.target.classList.remove("d-none");

        if (entry.target === componentRef1.current) {
          entry.target.classList.add(
            "animate__animated",
            "animate__fadeInLeft",
            "animate__slow",
            "animate__delay-1s"
          );
        } else if (entry.target === componentRef2.current) {
          entry.target.classList.add(
            "animate__animated",
            "animate__fadeInRight",
            "animate__slow",
            "animate__delay-1s"
          );
        }
      }
    });
  };

  return (
    <>
      <div ref={componentRef1} className="story-content">
        <h2>Câu chuyện</h2>
        <p>
          <span>Team Raining</span> thành lập vào năm 2023, mục tiêu chúng tôi
          hướng đến là thúc đẩy sự phát triển mạnh mẽ của thương hiệu Trà và Cà
          phê Trường Tín. Với sự đa dạng tính cách của mỗi cá nhân và tài năng
          đội ngũ, chúng tôi cam kết tạo ra những sản phẩm truyền thông độc đáo,
          sáng tạo, mang lại cái nhìn mới lạ và thú vị về Thương hiệu Trường Tín
          cho khách hàng trong tương lai.
        </p>
      </div>

      <div ref={componentRef2} className="story-image">
        <img src={storyImage} loading="lazy" alt="Câu chuyện của chúng tôi" />
      </div>
    </>
  );
}
