import * as React from "react";
import { IProduct } from "../../../../models/product.type";
import "./index.scss";

export interface IProductItemProps {
  product: IProduct;
  setSelectedProduct: React.Dispatch<React.SetStateAction<IProduct>>;
  setCurrentImage: React.Dispatch<React.SetStateAction<number>>;
}

export default function ProductItem({
  product,
  setSelectedProduct,
  setCurrentImage,
}: IProductItemProps) {
  return (
    <div
      className="product-item"
      onClick={() => {
        setSelectedProduct(product);
        setCurrentImage(0);
        window.scrollTo(0,10);
      }}
    >
      <div className="img-wrapper">
        <img loading="lazy" src={product.image[0]} alt={product.name} />
      </div>
      <p>{product.name}</p>
      
    </div>
  );
}
