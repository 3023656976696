import image1 from "../assets/images/image-1.jpg";
import image2 from "../assets/images/image-2.jpg";
import image3 from "../assets/images/image-3.jpg";
import image4 from "../assets/images/image-4.jpg";
import image5 from "../assets/images/image-5.jpg";
import Leaf1 from "../assets/images/leaf-1.png";
import Leaf2 from "../assets/images/leaf-2.png";
import Leaf3 from "../assets/images/leaf-3.png";
import Leaf4 from "../assets/images/leaf-4.png";
import Leaf5 from "../assets/images/leaf-5.png";

export const PRODUCT_GROUP_IMAGES = [image1, image2, image3, image4, image5];
export const LEAF_IMAGES = [Leaf1, Leaf2, Leaf3, Leaf4, Leaf5];
