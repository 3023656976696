import { useContext, useState, useEffect } from "react";
import "./index.scss";
import { ProductContext } from "../../store/product-context";
import ProductDisplay from "./product-display";
import ProductCarousel from "./product-carousel";

export interface IProductPageProps {}

export default function ProductPage(props: IProductPageProps) {
  const { selectedProduct, setSelectedProduct } = useContext(ProductContext);
  const [currentImage, setCurrentImage] = useState(0);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const minScreenWidth = 1281;
  return (
    <div
      className={`${
        windowWidth >= minScreenWidth ? "product-desktop" : "product-mobile"
      }`}
    >
      <ProductDisplay
        selectedProduct={selectedProduct}
        currentImage={currentImage}
        setCurrentImage={setCurrentImage}
      />
      <h2>Các sản phẩm khác</h2>
      <ProductCarousel
        setCurrentImage={setCurrentImage}
        setSelectedProduct={setSelectedProduct}
        selectedProduct={selectedProduct}
      />
    </div>
  );
}
