import * as React from "react";
import "./index.scss";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import productList from "../../../constants/productList";
import ProductItem from "../../../components/shared/home-product-item";

export interface IProductListProps {}

export default function ProductList(props: IProductListProps) {
  const [index, setIndex] = React.useState(0);
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const minScreenWidth = 1281;
  const [productGroups, setProductGroups] = React.useState(
    productList.slice(0, 4)
  );

  const handlePrev = () => {
    const newIndex = (index - 1 + productList.length) % productList.length;
    const productGroupCount = Math.min(4, productList.length - newIndex);
    const newProductGroups = [
      ...productList.slice(newIndex, newIndex + productGroupCount),
      ...(productGroupCount < 4
        ? productList.slice(0, 4 - productGroupCount)
        : []),
    ];

    setIndex(newIndex);
    setProductGroups(newProductGroups);
  };

  const handleNext = () => {
    const newIndex = (index + 1) % productList.length;
    const productGroupCount = Math.min(4, productList.length - newIndex);
    const newProductGroups = [
      ...productList.slice(newIndex, newIndex + productGroupCount),
      ...(productGroupCount < 4
        ? productList.slice(0, 4 - productGroupCount)
        : []),
    ];

    setIndex(newIndex);
    setProductGroups(newProductGroups);
  };

  if (windowWidth >= minScreenWidth)
    return (
      <div className="home-product-desktop">
        <h2 className="home-product-title">Các sản phẩm tiêu biểu</h2>
        <div className="home-product-items">
          <button className="button" onClick={handlePrev}>
            <FontAwesomeIcon icon={faChevronLeft} className="text-2xl" />
          </button>
          <div className="product-list">
            {productGroups.map((productGroup, index) => (
              <ProductItem key={index} productGroup={productGroup} />
            ))}
          </div>
          <button className="button" onClick={handleNext}>
            <FontAwesomeIcon icon={faChevronRight} className="text-2xl" />
          </button>
        </div>
      </div>
    );
    return (
      <div className="home-product-mobile">
        <h2 className="home-product-title">Các sản phẩm tiêu biểu</h2>
        <div className="home-product-items">
          <div className="product-list">
            {productList.map((productGroup, index) => (
              <ProductItem key={index} productGroup={productGroup} />
            ))}
          </div>
        </div>
      </div>
    );
}
