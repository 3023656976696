import * as React from "react";
import "./index.scss";
import Section from "./section";
import Introduction from "./introduction";
import Story from "./story";
import HomeCarousel from "./carousel";
import ProductList from "./product-list";
import { Helmet, HelmetProvider } from "react-helmet-async";

export interface IHomePageProps {}

export default function HomePage(props: IHomePageProps) {
  return (
    <div className="home-container">
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Trường Tín | Trà và Cà phê</title>
          <link rel="canonical" href="https://truongtintc.com" />
        </Helmet>
      </HelmetProvider>
      <Section flex>
        <Introduction />
      </Section>

      <Section flex>
        <Story />
      </Section>

      <Section>
        <ProductList />
      </Section>

      <Section>
        <HomeCarousel />
      </Section>
    </div>
  );
}
