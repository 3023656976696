import * as React from "react";
import "./index.scss";
import { NavLink } from "react-router-dom";
import logoGreen from "../../../assets/images/logo-green.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

export interface IHeaderProps {}

export default function Header(props: IHeaderProps) {
  const [showNavbar, setShowNavbar] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const minScreenWidth = 1281;

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  if (windowWidth >= minScreenWidth)
    return (
      <header className="header-desktop">
        <div className="logo">
          <img loading="lazy" src={logoGreen} alt="logo" />
        </div>
        <ul>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? "active" : undefined)}
            >
              Trang chủ
            </NavLink>
          </li>
          <li>
            <NavLink
              to="product"
              className={({ isActive }) => (isActive ? "active" : undefined)}
            >
              Sản phẩm
            </NavLink>
          </li>
          {/* <li><NavLink to="discover" className={({ isActive })=> isActive ? "active" : undefined}>Khám phá</NavLink></li> */}
        </ul>
      </header>
    );
  return (
    <header className="header-mobile">
      <div className="header">
        <div className="logo">
          <img loading="lazy" src={logoGreen} alt="logo" />
        </div>
        <div className="mobile-menu" onClick={handleShowNavbar}>
          <FontAwesomeIcon icon={faBars} />
        </div>
      </div>
      <ul
        className={`animate__animated ${
          showNavbar ? "animate__slideInRight" : "animate__slideOutRight"
        }`}
      >
        <li>
          <NavLink
            onClick={handleShowNavbar}
            to="/"
            className={({ isActive }) => (isActive ? "active" : undefined)}
          >
            Trang chủ
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={handleShowNavbar}
            to="product"
            className={({ isActive }) => (isActive ? "active" : undefined)}
          >
            Sản phẩm
          </NavLink>
        </li>
        {/* <li><NavLink to="discover" className={({ isActive })=> isActive ? "active" : undefined}>Khám phá</NavLink></li> */}
      </ul>
    </header>
  );
}
