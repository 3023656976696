import * as React from "react";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IProduct } from "../../../models/product.type";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import facebookIcon from "../../../assets/images/facebook.png";
import shopeeIcon from "../../../assets/images/shopee.png";
import lazadaIcon from "../../../assets/images/lazada.png";
import {
  faChevronLeft,
  faChevronRight,
  faDotCircle,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";

export interface IProductDisplayProps {
  selectedProduct: IProduct;
  currentImage: number;
  setCurrentImage: React.Dispatch<React.SetStateAction<number>>;
}

export default function ProductDisplay({
  selectedProduct,
  currentImage,
  setCurrentImage,
}: IProductDisplayProps) {
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );

  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const minScreenWidth = 1281;

  const prevSlide = () => {
    const isFirstSlide = currentImage === 0;
    const index = isFirstSlide
      ? selectedProduct.image.length - 1
      : currentImage - 1;
    setCurrentImage(index);
  };
  const nextSlide = () => {
    const isLastSlide = currentImage === selectedProduct.image.length - 1;
    const index = isLastSlide ? 0 : currentImage + 1;
    setCurrentImage(index);
  };
  const jumpToSlide = (index: number) => {
    setCurrentImage(index);
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{selectedProduct.name} | Trường Tín</title>
          <link rel="canonical" href="http://truongtintc.com/product" />
        </Helmet>
      </HelmetProvider>
      {windowWidth < minScreenWidth && (
        <div className="product-display-mobile">
          <div className="img-container">
            <div
              style={{
                backgroundImage: `url(${selectedProduct.image[currentImage]})`,
              }}
              className="img-display"
            />

            <div onClick={prevSlide} className="button button-prev">
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>

            <div onClick={nextSlide} className="button button-next">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>

            <div className="change-group">
              {selectedProduct.image.map((image, index) => (
                <div
                  key={index}
                  onClick={() => jumpToSlide(index)}
                  className="change-item"
                >
                  {index === currentImage && (
                    <FontAwesomeIcon icon={faDotCircle} />
                  )}
                  {!(index === currentImage) && (
                    <FontAwesomeIcon icon={faCircle} />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="product-info">
            <h1>{selectedProduct.name}</h1>
            <div className="long-line" />
            <p>{selectedProduct.ingredients}</p>
            <div className="short-line" />

            <div className="product-price">
              <h4>Giá sản phẩm:</h4>

              <ul>
                {selectedProduct.price.map((price, index) => {
                  return (
                    <li key={index} className="price">
                      {price}
                    </li>
                  );
                })}
              </ul>
              <ul>
                {selectedProduct.description?.map((description, index) => {
                  return (
                    <li className="description" key={index}>
                      {description}
                    </li>
                  );
                })}
              </ul>
            </div>

            <h4>Liên hệ với chúng tôi:</h4>
            <div className="contact-link">
              <Link
                className="facebook"
                to="https://facebook.com/TruongTinTea"
                target="_blank"
              >
                <img
                  loading="lazy"
                  src={facebookIcon}
                  width={26}
                  height={26}
                  alt="Facebook Trường Tín"
                />{" "}
                Mua ngay
              </Link>
              <Link
                className="shopee"
                to=""
                target="_blank"
                onClick={(Event) => {
                  Event.preventDefault();
                  alert("Hiện chưa hỗ trợ nền tảng này!");
                }}
              >
                <img
                  loading="lazy"
                  src={shopeeIcon}
                  width={26}
                  height={26}
                  alt="Shopee Trường Tín"
                />{" "}
                Mua ngay
              </Link>
              <Link
                className="lazada"
                to=""
                target="_blank"
                onClick={(Event) => {
                  Event.preventDefault();
                  alert("Hiện chưa hỗ trợ nền tảng này!");
                }}
              >
                <img
                  loading="lazy"
                  src={lazadaIcon}
                  width={26}
                  height={26}
                  alt="Lazada Trường Tín"
                />{" "}
                Mua ngay
              </Link>
            </div>
          </div>
          <div className="divider"/>
        </div>
      )}

      {/* DESKTOP VIEW */}

      {windowWidth >= minScreenWidth && (
        <div className="product-display-desktop">
          <div className="img-container">
            <div
              style={{
                backgroundImage: `url(${selectedProduct.image[currentImage]})`,
              }}
              className="img-display"
            />

            <div onClick={prevSlide} className="button button-prev">
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>

            <div onClick={nextSlide} className="button button-next">
              <FontAwesomeIcon icon={faChevronRight} />
            </div>

            <div className="change-group">
              {selectedProduct.image.map((image, index) => (
                <div
                  key={index}
                  onClick={() => jumpToSlide(index)}
                  className="change-item"
                >
                  {index === currentImage && (
                    <FontAwesomeIcon icon={faDotCircle} />
                  )}
                  {!(index === currentImage) && (
                    <FontAwesomeIcon icon={faCircle} />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="product-info">
            <h1>{selectedProduct.name}</h1>
            <div className="long-line" />
            <p>{selectedProduct.ingredients}</p>
            <div className="short-line" />

            <div className="product-price">
              <ul>
                {selectedProduct.description?.map((description, index) => {
                  return (
                    <li className="description" key={index}>
                      {description}
                    </li>
                  );
                })}
              </ul>
              <h4>Giá sản phẩm:</h4>

              <ul>
                {selectedProduct.price.map((price, index) => {
                  return (
                    <li key={index} className="price">
                      {price}
                    </li>
                  );
                })}
              </ul>
            </div>

            <h4>Liên hệ với chúng tôi:</h4>
            <div className="contact-link">
              <Link
                className="facebook"
                to="https://facebook.com/TruongTinTea"
                target="_blank"
              >
                <img
                  loading="lazy"
                  src={facebookIcon}
                  width={32}
                  height={32}
                  alt="Facebook Trường Tín"
                />{" "}
                Mua ngay
              </Link>
              <Link
                className="shopee"
                to=""
                target="_blank"
                onClick={(Event) => {
                  Event.preventDefault();
                  alert("Hiện chưa hỗ trợ nền tảng này!");
                }}
              >
                <img
                  loading="lazy"
                  src={shopeeIcon}
                  width={32}
                  height={32}
                  alt="Shopee Trường Tín"
                />{" "}
                Mua ngay
              </Link>
              <Link
                className="lazada"
                to=""
                target="_blank"
                onClick={(Event) => {
                  Event.preventDefault();
                  alert("Hiện chưa hỗ trợ nền tảng này!");
                }}
              >
                <img
                  loading="lazy"
                  src={lazadaIcon}
                  width={32}
                  height={32}
                  alt="Lazada Trường Tín"
                />{" "}
                Mua ngay
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
