import { useState } from "react";
import "./index.scss";
import Carousel from "react-bootstrap/Carousel";
import { PRODUCT_GROUP_IMAGES } from "../../../constants/images";
export interface IHomeCarouselProps {}
const data = [
  {
    caption: "Bước 1: Chuẩn bị loại trà",
    detail: [
      "Chuẩn bị loại trà mà bạn muốn thưởng thức, và dụng cụ pha trà. ",
      "Ví dụ : đối với trà ô long để đạt được hương vị ngon nhất, đậm đà nhất thì nên sử dụng loại ấm đất nung.",
    ],
  },
  {
    caption: "Bước 2: Hãm trà ",
    detail: [
      "Để khoảng 10g trà vào ấm, sau đó đổ một lượng nước sôi vừa phải (khoảng nữa ấm trà) để hãm trà (tầm hai đến năm phút).",
    ],
  },
  {
    caption: "Bước 3: Pha trà",
    detail: [
      "Chất hết toàn bộ nước hãm trà, sau đó thêm nước sôi đầy ấm đợi khoảng từ một tới hai phút trong khoảng thời gian đấy chúng ta đổ thêm nước sôi ở ngoài ấm để đảm bảo nhiệt độ của ấm nhằm tạo ra hương vị tuyệt hảo nhất.",
    ],
  },
  {
    caption: "Bước 4: Lọc cặn trà",
    detail: [
      "Chuẩn bị một chiếc phễu lọc trà, sau đó đổ nước trà từ ấm vào phễu với mục đích lọc hết cặn trà để khi chúng ta thưởng thức sẽ không bị cảm giác khó chịu do cặn trà gây ra.",
    ],
  },
  {
    caption: "Bước 5: Thay trà và hãm trà trong những lần tiếp theo",
    detail: [
      "Thưởng thức trà, sau khi châm đủ bốn lần nước, hãy thay trà trong ấm và thực hiện lại bước hãm trà với mức thời gian hợp lí phù hợp với khẩu vị của bản thân.",
    ],
  },
];
export default function HomeCarousel(props: IHomeCarouselProps) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <div className="home-carousel">
        <div className="carousel-intro">
          <span>HƯỚNG DẪN</span>
          <h2 className="carousel-title animate__animated animate__pulse animate__infinite animate__slower">
            Cách pha trà ngon
          </h2>
          <p>
            Cách pha trà đúng cách sẽ giúp bạn có được một tách trà ngon nhất.
          </p>
        </div>

        <Carousel
          activeIndex={index}
          className="carousel"
          onSelect={handleSelect}
          interval={3600000}
        >
          {data.map((item, index) => (
            <Carousel.Item key={index}>
              <img src={PRODUCT_GROUP_IMAGES[index]} alt="ảnh trà" loading="lazy" />
              <Carousel.Caption>
                <h3 className="animate__animated animate__slideInUp">
                  {item.caption}
                </h3>
                {item.detail.map((item, index) => (
                  <p key={index} className="animate__animated animate__zoomIn">
                    {item}
                  </p>
                ))}
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </>
  );
}
