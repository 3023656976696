import { useRef, useEffect } from "react";
import "./index.scss";
import introImage from "../../../assets/images/intro-image.jpg";
import { useTypewriter } from "react-simple-typewriter";
export interface IIntroductionProps {}

export default function Introduction(props: IIntroductionProps) {
  const [text] = useTypewriter({
    words: ["là Trường Tín", "cung cấp Trà", "cung cấp Cà phê"],
    loop: 0,
    typeSpeed: 120,
    deleteSpeed: 30,
    delaySpeed: 1000,
  });

  const componentRef1 = useRef(null);
  const componentRef2 = useRef(null);

  useEffect(() => {
    const observer1 = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.0,
    });

    const observer2 = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.0,
    });
    const ref1 = componentRef1.current;
    const ref2 = componentRef2.current;
  
    if (ref1) {
      observer1.observe(ref1);
    }
  
    if (ref2) {
      observer2.observe(ref2);
    }
  
    return () => {
      if (ref1) {
        observer1.unobserve(ref1);
      }
  
      if (ref2) {
        observer2.unobserve(ref2);
      }
    };
  }, [componentRef1, componentRef2]);

  const handleIntersection = (entries: any) => {
    entries.forEach((entry: any) => {
      if (entry.isIntersecting) {
        entry.target.classList.remove("d-none");

        if (entry.target === componentRef1.current) {
          // Thêm hiệu ứng cho componentRef1
          entry.target.classList.add(
            "animate__animated",
            "animate__fadeInLeft",
            "animate__slow",
            "animate__delay-1s"
          );
        } else if (entry.target === componentRef2.current) {
          // Thêm hiệu ứng cho componentRef2
          entry.target.classList.add(
            "animate__animated",
            "animate__fadeInRight",
            "animate__slow",
            "animate__delay-1s"
          );
        }
      }
    });
  };
  return (
    <>
      <div ref={componentRef1} className="introduction-image">
        <img loading="lazy" src={introImage} alt="Ảnh phần giới thiệu" />
      </div>

      <div ref={componentRef2} className="introduction-content">
        <h2>
          Chúng tôi<span>{text}</span>
        </h2>
        <p>
          Trà và Cà phê Trường Tín, thành lập 2014 tại "thiên đường" chè và cà
          phê, chinh phục người yêu trà với đa dạng sản phẩm từ Oolong đến cà
          phê. Với tôn chỉ "Sự khởi đầu của những hương vị bất tận," không chỉ
          là thương hiệu mà là nguồn cảm hứng cho cộng đồng. Cam kết đổi mới,
          tôn trọng ý kiến khách hàng, Trường Tín tạo giá trị về vị giác và cảm
          xúc. Hoạt động toàn diện từ nông nghiệp đến kinh doanh trà và cà phê
          trên toàn quốc. Với lịch sử trà từ Trung Quốc đến Châu Âu, Trường Tín
          không chỉ là doanh nghiệp xuất sắc mà còn là nguồn động viên cho cộng
          đồng người yêu trà Việt Nam.
        </p>
      </div>
    </>
  );
}
