import * as React from 'react';
import './index.scss';

export interface ISectionProps {
  children: React.ReactNode;
  flex?: boolean;
}

export default function Section (props: ISectionProps) {
  const className = props.flex ? 'section-container flex' : 'section-container';
  return (
    <div className={className}>
      {props.children}
    </div>
  );
}
