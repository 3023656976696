import { IProductGroup } from "../models/product.type";
import oolong618_1 from "../assets/images/tra_oolong_618/image_1.jpg";
import oolong618_2 from "../assets/images/tra_oolong_618/image_2.jpg";
import oolong618_3 from "../assets/images/tra_oolong_618/image_3.jpg";
import oolong618_4 from "../assets/images/tra_oolong_618/image_4.jpg";
import oolong618_5 from "../assets/images/tra_oolong_618/image_5.jpg";
import oolong618_6 from "../assets/images/tra_oolong_618/image_6.jpg";
import oolong612_1 from "../assets/images/tra_oolong_612/image_1.jpg";
import oolong_hop_1 from "../assets/images/tra_oolong_hop/image_1.jpg";
import oolong_hop_2 from "../assets/images/tra_oolong_hop/image_2.jpg";
import oolong_5sao_1 from "../assets/images/tra_oolong_5sao/image_1.jpg";
import oolong_3sao_1 from "../assets/images/tra_oolong_3sao/image_1.jpg";
import oolong_3sao_2 from "../assets/images/tra_oolong_3sao/image_2.jpg";
import oolong_lon_do_1 from "../assets/images/tra_oolong_lon_do/image_1.jpg";
import oolong_lon_do_2 from "../assets/images/tra_oolong_lon_do/image_2.jpg";
import lai_1 from "../assets/images/tra_lai/image_1.jpg";
import lai_2 from "../assets/images/tra_lai/image_2.jpg";
import lai_goi_1 from "../assets/images/tra_lai_goi/image_1.jpg";
import lai_goi_2 from "../assets/images/tra_lai_goi/image_2.jpg";
import atiso_1 from "../assets/images/tra_atiso/image_1.jpg";
import cafe_1 from "../assets/images/ca_phe_mountain/image_1.jpg";
import cafe_2 from "../assets/images/ca_phe_mountain/image_2.jpg";


const productList: IProductGroup[] = [
  {
    title: "Trà Oolong",
    products: [
      {
        name: "Trà Oolong 618",
        ingredients:
          "100% đọt trà Oolong cao cấp chất lượng cao, được trồng trọt và tuyển chọn kĩ càng trong quy trình sản xuất.",
        price: ["145.000VNĐ / 1 gói (250gr)", "60.000VNĐ / 1 gói (100gr)"],
        description: [
          "Thành phần: Đọt trà oolong sạch (tứ quý, thanh tâm, 1 tôm 2 lá) chất lượng cao, được trồng trọt và  chăm sóc hữu cơ. Chế biến lên men tự nhiên, giúp trà có hương thơm hoa, vị đắng, chát nhẹ hậu ngọt.",
        ],
        image: [oolong618_1, oolong618_2, oolong618_3, oolong618_4, oolong618_5, oolong618_6],
      },
      {
        name: "Trà Oolong 612",
        ingredients:
          "100% đọt trà Oolong cao cấp chất lượng cao, được trồng trọt và tuyển chọn kĩ càng trong quy trình sản xuất.",
        price: ["105.000VNĐ / 1 gói (250gr)", "45.000VNĐ / 1 gói (100gr)"],
        description: [
          "Hạn sử dụng: 2 năm.",
          "Kích thước: 4cm x 8cm x 13cm (250gr), 4cm x 8cm x 6cm (100gr)",
        ],
        image: [oolong612_1],
      },
      {
        name: "Trà Oolong hộp",
        ingredients:
          "Đọt trà oolong sạch (tứ quý, thanh tâm, 1 tôm 2 lá) chất lượng cao, được trồng trọt và chăm sóc hữu cơ.",
        price: ["130.000VNĐ / 1 hộp (200gr)"],
        description: [
          "Chế biến lên men tự nhiên, giúp trà có hương thơm hoa, vị đắng, chát nhẹ hậu ngọt.",
        ],
        image: [oolong_hop_1, oolong_hop_2],
      }
    ],
    priceRange: "45.000 - 145.000VNĐ",
  },
  {
    title: "Trà Oolong cao cấp 5 sao",
    products: [
      {
        name: "Trà Oolong cao cấp hộp đỏ 5 sao",
        ingredients:
          "Trà Oolong được chế biến thành phẩm và đóng gói trong sản phẩm, là những đọt trà Oolong cao cấp nhất, được giám sát kĩ càng trong từng công đoạn sản xuất, để đảm bảo có một hương vị chất lượng nhất đối với những người yêu trà.",
        price: ["850.000VNĐ / 1 hộp (400gr)"],
        description: [
          "Sản phẩm được thiết kế dễ dàng sử dụng, khi có 40 gói trà nhỏ trọng lượng 10gr và được chia đều trong 2 hộp nhỏ, nhằm giúp cho việc pha trà nhanh và tiện lợi, khách hàng không cần phải cân đo trọng lượng trà, để pha như các dòng sản phẩm khác của Trường Tín."
        ],
        image: [oolong_5sao_1],
      },
    ],
    priceRange: "850.000VNĐ",
  },
  {
    title: "Trà Oolong cao cấp 3 sao",
    products: [
      {
        name: "Trà Oolong cao cấp hộp xanh 3 sao",
        ingredients:
          "100% đọt trà Oolong cao cấp, không thêm chất phụ gia, đảm bảo mỗi tách trà được pha ra có khoảng 2 calories.",
        price: ["560.000VNĐ / 1 hộp (400gr)"],
        image: [oolong_3sao_1, oolong_3sao_2],
      },
    ],
    priceRange: "560.000VNĐ",
  },
  {
    title: "Trà lon",
    products: [
        {
            name: "Trà Oolong lon đỏ",
            price: ["80.000VNĐ / 1 lon"],
            ingredients: "Đọt trà Oolong cao cấp chất lượng cao, được trồng trọt và tuyển chọn kĩ càng trong quy trình sản xuất.",
            description: [
                "Thiết kế: Lon giấy thiếc đỏ, và một gói trà Oolong 618 (100gr) được đặt trong mỗi lon."
            ],
        image: [oolong_lon_do_1, oolong_lon_do_2],
      },
        {
            name: "Trà lài lon",
            price: ["35.000VNĐ / 1 lon"],
            ingredients: "Sản phẩm được đưa vào sản xuất với quy trình hiện đại, và được ướp hoàn toàn 100% hoa lài, để cho sản phẩm có thể đạt được hương vị và chất lượng tốt nhất.",
        image: [lai_1, lai_2],
        },
        {
            name: "Trà lài gói",
            price: ["48.000VNĐ / 1 pack (70gr/ 6 gói)"],
            ingredients: "Sản phẩm được đưa vào sản xuất với quy trình hiện đại, và được ướp hoàn toàn 100% hoa lài, để cho sản phẩm có thể đạt được hương vị và chất lượng tốt nhất.",
        image: [lai_goi_1, lai_goi_2],
        }
    ],
    priceRange: "35.000 - 80.000VNĐ",
  },
  {
    title: "Trà atiso",
    products: [{
        name: "Trà atiso túi lọc",
        price: ["72.000VNĐ / 1 gói (200gr)"],
        ingredients: "92% Atiso cao cấp, được trồng trọt, thu hoạch, và đưa vào quy trình sản xuất khép kín cùng với tỉ lệ 8% cỏ ngọt.",
        image: [atiso_1],
    }],
    priceRange: "72.000VNĐ",
    },
    {
      title: "Cà phê",
    products: [{
        name: "Cà phê Mountain",
        price: ["80.000VNĐ / 1 cây (6 gói)"],
        ingredients: "Cà phê Abrica, Robusta phối trộn theo tỷ lệ nhất định, quá trình rang xay, giúp  cà phê có hương thơm tự nhiên đặc trưng.",
        image: [cafe_1, cafe_2],
    }],
    priceRange: "80.000VNĐ",
    }
];

export default productList;
