import "./index.scss";
import { useContext } from "react";
import { IProductGroup } from "../../../models/product.type";
import { useNavigate } from "react-router-dom";
import { ProductContext } from "../../../store/product-context";

export interface IProductItemProps {
  productGroup: IProductGroup;
}

export default function ProductItem({ productGroup }: IProductItemProps) {
  const navigate = useNavigate();
  const { setSelectedProduct } = useContext(ProductContext);

  const navigateToProduct = () => {
    setSelectedProduct(productGroup.products[0]);
    navigate(`product`);

  };
  return (
    <div className="home-product-item">
      <div className="img-wrapper">
      <img loading="lazy" src={productGroup.products[0].image[0]} alt={productGroup.title} />
      </div>

      <div className="product-description">
        <h3>{productGroup.title}</h3>
          <span>Giá chỉ từ: </span>
        <h4>
          {productGroup.priceRange}
        </h4>
      </div>
      <button onClick={navigateToProduct} className="product-link">
        Chi tiết
      </button>
    </div>
  );
}
