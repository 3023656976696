import "./index.scss";
import { LEAF_IMAGES } from "../../../constants/images";

export interface ILeafProps {}

export default function Leaf(props: ILeafProps) {
  return (
    <div className="leafs_wrap">
      <div className="leaf x1">
        <img src={LEAF_IMAGES[0]} alt="leaf" />
      </div>
      <div className="leaf x2">
        <img src={LEAF_IMAGES[1]} alt="leaf" />
      </div>
      <div className="leaf x3">
        <img src={LEAF_IMAGES[2]} alt="leaf" />
      </div>
      <div className="leaf x4">
        <img src={LEAF_IMAGES[3]} alt="leaf" />
      </div>
      <div className="leaf x5">
        <img src={LEAF_IMAGES[4]} alt="leaf" />
      </div>
      <div className="leaf x6">
        <img src={LEAF_IMAGES[0]} alt="leaf" />
      </div>
      <div className="leaf x7">
        <img src={LEAF_IMAGES[1]} alt="leaf" />
      </div>
      <div className="leaf x8">
        <img src={LEAF_IMAGES[2]} alt="leaf" />
      </div>
      <div className="leaf x9">
        <img src={LEAF_IMAGES[3]} alt="leaf" />
      </div>
      <div className="leaf x10">
        <img src={LEAF_IMAGES[4]} alt="leaf" />
      </div>
    </div>
  );
}
