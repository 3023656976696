import * as React from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

export interface IErrorPageProps {}

export default function ErrorPage(props: IErrorPageProps) {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Không tìm thấy đường dẫn | Trường Tín</title>
          <link rel="canonical" href="https://truongtintc.com" />
        </Helmet>
      </HelmetProvider>

      <section className="error-container">
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
        <span className="zero">
          <span className="screen-reader-text">0</span>
        </span>
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
        <h3>
          Trang không tồn tại! <Link to={"/"}>Quay lại trang chủ</Link>
        </h3>
      </section>
    </>
  );
}
