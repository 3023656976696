import * as React from "react";
import "./index.scss";
import logoWhite from "../../../assets/images/logo-white.png";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTiktok, faFacebook } from "@fortawesome/free-brands-svg-icons";
import Shopee from "../../../assets/images/shopee-white.png";
import { Link } from "react-router-dom";
export interface IFooterProps {}

export default function Footer(props: IFooterProps) {
  return (
    <footer>
      <div className="footer_content">
        <div className="logo">
          <img src={logoWhite} alt="logo" />
        </div>

        <div className="about-me">
          <p className="title">Về chúng tôi</p>
          <p className="description">
            Trường Tín sẽ không ngừng cải tiến và nâng cấp sản phẩm nhằm hướng
            tới tương lai đáp ứng được nhu cầu của khách hàng khi đặt kì vọng
            vào doanh nghiệp. Sản phẩm của Trường Tín sẽ luôn đáp ứng được cho
            khách hàng về phương diện vị giác, bên cạnh đó sản phẩm Trường Tín
            cũng trung thành trong phương diện bảo vệ sức khỏe của người tiêu
            dùng.
          </p>
        </div>

        <div className="information">
          <div className="item">
            <FontAwesomeIcon icon={faPhone} />
            <span>0962193079</span>
          </div>
          <div className="item">
            <FontAwesomeIcon icon={faEnvelope} />
            <span>truongtinbussinesscompany@gmail.com</span>
          </div>
          <ul>
            <li>
              <Link
                to="https://www.tiktok.com/@truongtinteacoffee14?lang=vi-VN"
                target="_blank"
              >
                <FontAwesomeIcon icon={faTiktok} />
              </Link>
            </li>
            <li>
              <Link to="https://facebook.com/TruongTinTea" target="_blank">
                <FontAwesomeIcon icon={faFacebook} />
              </Link>
            </li>
            <li>
              <Link
                to=""
                onClick={(Event) => {
                  Event.preventDefault();
                  // alert("Hiện chưa hỗ trợ nền tảng này!");
                }}
              >
                <img src={Shopee} alt="shopee" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
